<template>
  <div>
    <h1 class="my-4">ВП из сторис</h1>
    <div class="mt-1 mb-4" v-if="!loading">
      <div v-if="vpProfile">
        <Card
          v-if="vpProfileCoverageMatch"
          :storiesRequest="true"
          :account="vpProfile"
          @stories-request="() => (showProfileOverlay = false)"
          @stories-request-fail="() => (showProfileOverlay = false)"
        />

        <div v-else class="hovering__pad p-4">
          К сожалению вы не можете отправить заявку на ВП данному пользователю, так как ваши охваты
          сторис не попадают в допустимый диапазон.
        </div>
      </div>
      <div v-else class="hovering__pad p-4">
        К сожалению вы не можете отправить заявку на ВП данному пользователю, профиля нет в системе
        либо он был удален
      </div>
    </div>
    <loader v-else />
  </div>
</template>

<script>
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import users from '@main/api/users';
import { mapState, mapActions, mapMutations } from 'vuex';
import Loader from '@main/components/Loader';
import Card from '@mp/components/Card/Card.vue';

export default {
  components: {
    Card,
    Loader,
  },
  props: {
    userId: [String, Number],
  },
  data: () => ({
    showProfileOverlay: false,
    vpProfile: null,
    loading: true,
  }),
  computed: {
    ...mapState(['currentUser']),
    vpProfileCoverageMatch() {
      if (!this.vpProfile) {
        return;
      }
      let min_cov = Math.round(
        this.vpProfile.blogger_profile.avg_coverage -
          this.vpProfile.blogger_profile.avg_coverage * 0.5
      );
      console.log(min_cov);
      let max_cov = Math.round(
        this.vpProfile.blogger_profile.avg_coverage +
          this.vpProfile.blogger_profile.avg_coverage * 0.5
      );
      console.log(max_cov);
      console.log(this.currentUser.blogger_profile.avg_coverage);
      return (
        max_cov >= this.currentUser.blogger_profile.avg_coverage &&
        min_cov <= this.currentUser.blogger_profile.avg_coverage
      );
    },
  },
  async mounted() {
    try {
      const response = await users.get(this.userId);
      if (!response.error) {
        this.vpProfile = response;
      }
    } catch (e) {
      console.log(e);
      this.vpProfile = null;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="css" scoped></style>
